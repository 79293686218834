@import url('./slider_media.css');
.Comparison-Slider .carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.Comparison-Slider .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.Comparison-Slider .slide {
    flex: 0 0 100%;
    width: 100%;
}

.Comparison-Slider .carousel {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius:16px;
}

.Comparison-Slider .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.Comparison-Slider .slide {
    flex: 0 0 100%;
    width: 100%;
}

.Comparison-Slider .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.Comparison-Slider .dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #bbb;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
    border: 0;
    box-shadow: 0 0 10px #bfbfbf;
}

.Comparison-Slider .dot.active {
    background-color: #333;
}

.Comparison-Slider img {
    max-height: 550px;
    object-fit: cover !important;
}

.sliderText {
    text-align: center;
    margin-bottom: 36px;
}



/* Testimonial Slider */

.testim {
    width: 100%;
    background-color: #fff;
    border: 2px solid;
    border-color: var(--primary);
    border-radius: 30px;
}

.testim .wrap {
    position: relative;
    width: 100%;
    max-width: 1020px;
    padding: 40px 20px;
    margin: auto;
}

.testim .arrow {
    display: block;
    position: absolute;
    color: #eee;
    cursor: pointer;
    font-size: 2em;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding: 5px;
    z-index: 9999;
    height: 50px;
    width: 50px;
    background: url(/public/assets/images/arrow.png) no-repeat center;
    background-size: contain;
    /* filter: brightness(0) saturate(100%) invert(66%) sepia(53%) saturate(247%) hue-rotate(6deg) brightness(88%) contrast(85%); */
    filter: brightness(0) saturate(100%) invert(21%) sepia(100%) saturate(424%) hue-rotate(150deg) brightness(93%) contrast(91%);
}

.testim .arrow:before {
    cursor: pointer;
}

.testim .arrow:hover {
    color: var(--primary);
}


.testim .arrow.left {
    left: 10px;
    transform: translateY(-50%) rotate(180deg);
}

.testim .arrow.right {
    right: 10px;
}

.testim .dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
    display: block;
    z-index: 3333;
    height: 12px;
}

.testim .dots .dot {
    list-style-type: none;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #858282;
    margin: 0 10px;
    cursor: pointer;
    -webkit-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    position: relative;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.testim .dots .dot.active {
    -webkit-animation: testim-scale .5s ease-in-out forwards;
    -moz-animation: testim-scale .5s ease-in-out forwards;
    -ms-animation: testim-scale .5s ease-in-out forwards;
    -o-animation: testim-scale .5s ease-in-out forwards;
    animation: testim-scale .5s ease-in-out forwards;
}

.testim .cont {
    position: relative;
    overflow: hidden;
}

.testim .cont>div {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 70px 0;
    opacity: 0;
}

.testim .cont>div.inactive {
    opacity: 1;
}


.testim .cont>div.active {
    position: relative;
    opacity: 1;
}


.testim .cont div .img img {
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
    border-radius: 50%;
}

.testim .cont div h2 {
    color: var(--primary);
    font-size: 1em;
    margin: 15px 0;
}

.testim .cont div p {
    font-size: 1.15em;
    color: black;
    width: 80%;
    margin: auto;
}

.beforeafter-tag {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-inline: 20px;
    padding-block: 20px;
}

.beforeafter-tag span {
    background: #35A999;
    color: white;
    padding: 6px;
    /* border-radius: 7px; */
    font-size: 13px;
}



.testim .cont div.active .img img {
    -webkit-animation: testim-show .5s ease-in-out forwards;
    -moz-animation: testim-show .5s ease-in-out forwards;
    -ms-animation: testim-show .5s ease-in-out forwards;
    -o-animation: testim-show .5s ease-in-out forwards;
    animation: testim-show .5s ease-in-out forwards;
}

.testim .cont div.active h2 {
    -webkit-animation: testim-content-in .4s ease-in-out forwards;
    -moz-animation: testim-content-in .4s ease-in-out forwards;
    -ms-animation: testim-content-in .4s ease-in-out forwards;
    -o-animation: testim-content-in .4s ease-in-out forwards;
    animation: testim-content-in .4s ease-in-out forwards;
}

.testim .cont div.active p {
    -webkit-animation: testim-content-in .5s ease-in-out forwards;
    -moz-animation: testim-content-in .5s ease-in-out forwards;
    -ms-animation: testim-content-in .5s ease-in-out forwards;
    -o-animation: testim-content-in .5s ease-in-out forwards;
    animation: testim-content-in .5s ease-in-out forwards;
}

.testim .cont div.inactive .img img {
    -webkit-animation: testim-hide .5s ease-in-out forwards;
    -moz-animation: testim-hide .5s ease-in-out forwards;
    -ms-animation: testim-hide .5s ease-in-out forwards;
    -o-animation: testim-hide .5s ease-in-out forwards;
    animation: testim-hide .5s ease-in-out forwards;
}

.testim .cont div.inactive h2 {
    -webkit-animation: testim-content-out .4s ease-in-out forwards;
    -moz-animation: testim-content-out .4s ease-in-out forwards;
    -ms-animation: testim-content-out .4s ease-in-out forwards;
    -o-animation: testim-content-out .4s ease-in-out forwards;
    animation: testim-content-out .4s ease-in-out forwards;
}

.testim .cont div.inactive p {
    -webkit-animation: testim-content-out .5s ease-in-out forwards;
    -moz-animation: testim-content-out .5s ease-in-out forwards;
    -ms-animation: testim-content-out .5s ease-in-out forwards;
    -o-animation: testim-content-out .5s ease-in-out forwards;
    animation: testim-content-out .5s ease-in-out forwards;
}



@-webkit-keyframes testim-scale {
    0% {
        -webkit-box-shadow: 0px 0px 0px 0px #eee;
        box-shadow: 0px 0px 0px 0px #eee;
    }

    35% {
        -webkit-box-shadow: 0px 0px 10px 5px #eee;
        box-shadow: 0px 0px 10px 5px #eee;
    }

    70% {
        -webkit-box-shadow: 0px 0px 10px 5px var(--primary);
        box-shadow: 0px 0px 10px 5px var(--primary);
    }

    100% {
        -webkit-box-shadow: 0px 0px 0px 0px var(--primary);
        box-shadow: 0px 0px 0px 0px var(--primary);
    }
}

@-moz-keyframes testim-scale {
    0% {
        -moz-box-shadow: 0px 0px 0px 0px #eee;
        box-shadow: 0px 0px 0px 0px #eee;
    }

    35% {
        -moz-box-shadow: 0px 0px 10px 5px #eee;
        box-shadow: 0px 0px 10px 5px #eee;
    }

    70% {
        -moz-box-shadow: 0px 0px 10px 5px var(--primary);
        box-shadow: 0px 0px 10px 5px var(--primary);
    }

    100% {
        -moz-box-shadow: 0px 0px 0px 0px var(--primary);
        box-shadow: 0px 0px 0px 0px var(--primary);
    }
}

@-ms-keyframes testim-scale {
    0% {
        -ms-box-shadow: 0px 0px 0px 0px #eee;
        box-shadow: 0px 0px 0px 0px #eee;
    }

    35% {
        -ms-box-shadow: 0px 0px 10px 5px #eee;
        box-shadow: 0px 0px 10px 5px #eee;
    }

    70% {
        -ms-box-shadow: 0px 0px 10px 5px var(--primary);
        box-shadow: 0px 0px 10px 5px var(--primary);
    }

    100% {
        -ms-box-shadow: 0px 0px 0px 0px var(--primary);
        box-shadow: 0px 0px 0px 0px var(--primary);
    }
}

@-o-keyframes testim-scale {
    0% {
        -o-box-shadow: 0px 0px 0px 0px #eee;
        box-shadow: 0px 0px 0px 0px #eee;
    }

    35% {
        -o-box-shadow: 0px 0px 10px 5px #eee;
        box-shadow: 0px 0px 10px 5px #eee;
    }

    70% {
        -o-box-shadow: 0px 0px 10px 5px var(--primary);
        box-shadow: 0px 0px 10px 5px var(--primary);
    }

    100% {
        -o-box-shadow: 0px 0px 0px 0px var(--primary);
        box-shadow: 0px 0px 0px 0px var(--primary);
    }
}

@keyframes testim-scale {
    0% {
        box-shadow: 0px 0px 0px 0px #eee;
    }

    35% {
        box-shadow: 0px 0px 10px 5px #eee;
    }

    70% {
        box-shadow: 0px 0px 10px 5px var(--primary);
    }

    100% {
        box-shadow: 0px 0px 0px 0px var(--primary);
    }
}

@-webkit-keyframes testim-content-in {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes testim-content-in {
    from {
        opacity: 0;
        -moz-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
}

@-ms-keyframes testim-content-in {
    from {
        opacity: 0;
        -ms-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

@-o-keyframes testim-content-in {
    from {
        opacity: 0;
        -o-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes testim-content-in {
    from {
        opacity: 0;
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes testim-content-out {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-moz-keyframes testim-content-out {
    from {
        opacity: 1;
        -moz-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-ms-keyframes testim-content-out {
    from {
        opacity: 1;
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        -ms-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@-o-keyframes testim-content-out {
    from {
        opacity: 1;
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

@keyframes testim-content-out {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@-webkit-keyframes testim-show {
    from {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes testim-show {
    from {
        opacity: 0;
        -moz-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -moz-transform: scale(1);
        transform: scale(1);
    }
}

@-ms-keyframes testim-show {
    from {
        opacity: 0;
        -ms-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1);
    }
}

@-o-keyframes testim-show {
    from {
        opacity: 0;
        -o-transform: scale(0);
        transform: scale(0);
    }

    to {
        opacity: 1;
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes testim-show {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes testim-hide {
    from {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-moz-keyframes testim-hide {
    from {
        opacity: 1;
        -moz-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -moz-transform: scale(0);
        transform: scale(0);
    }
}

@-ms-keyframes testim-hide {
    from {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -ms-transform: scale(0);
        transform: scale(0);
    }
}

@-o-keyframes testim-hide {
    from {
        opacity: 1;
        -o-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 0;
        -o-transform: scale(0);
        transform: scale(0);
    }
}

@keyframes testim-hide {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

@media all and (max-width: 300px) {}

@media all and (max-width: 500px) {
    .testim .arrow {
        font-size: 1.5em;
    }

    .testim .cont div p {
        line-height: 25px;
    }

}



/* star rating slider */

.review {
    max-width: 900px;
    width: 100%;
    padding-inline: 10px;
    margin: 0 auto;
    text-align: center;
}

.review span.review-text {
    font-size: clamp(24px, 5vw, 44px);
    line-height: 1.2;
    font-family: 'CooperLtBT';
    font-weight: 400;
}

.review .star-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 30px;
}

.Comparison-Slider {
    position: relative;
    overflow: auto !important;
}

.Comparison-Slider button.prev-arrow,
.Comparison-Slider button.next-arrow{
    position: absolute;
    top: 50%;
    left: 10px;
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f2f5e9;
    color: #000;
    transform: translateY(-50%);
    box-shadow: 0 0 10px;
}

.Comparison-Slider button.next-arrow{
    left: unset;
    right: 10px;
}
ul.slick-dots{
}
ul.slick-dots li{
    transition-duration:0.5s;
}
ul.slick-dots li button:before{
    content:"";
    width:100%;
    height:25%;
    background-color:#1e4e59;
    border-radius:12px;
        
}
img.slick-disabled{
    opacity:0.2;
}
ul.slick-dots li.slick-active{
    transition-duration:0.2s;
    width:30px;
}
li:has(+li.slick-active) button::before{
    background-color:#267469;
    transition-duration:0.2s;
    opacity:0.6 !important;
    
}
li.slick-active + li button::before{
    transition-duration:0.2s;
    background-color:#267469;
    opacity:0.6 !important;
}