/* @import 'aos/dist/aos.css'; */
@import './variables.css';
@import '../src/component/Pages/Home/home.css';
@import './index_media.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

/* @font-face {
  font-family: 'cooperbi';
  src: url('/public/assets/font/Cooper\ Lt\ BT\ Light.woff2') format('woff2'),
    url('/public/assets/font/Cooper\ Lt\ BT\ Light.woff') format('woff'),
    url('/public/assets/font/Cooper\ Lt\ BT\ Light.ttf') format('truetype');
} */

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  /* it will break your bottom nev stickyness*/
  /* overflow-x: hidden; */
  /* background-image: url(/public/assets/images/green-blur-svg.svg); */
  /* background-color: #d3d9d46b !important; */
}

.ant-tabs,
.ant-menu,
.ant-col {
  font-family: 'Poppins', sans-serif !important;
}

a {
  text-decoration: none !important;
}

p,
li {
  font-family: "Poppins", serif;
}

.scroll-container {
  height: 100%;
  overflow: hidden;
  /* Hide native scrollbar */
}

section h2 {
  text-align: center;
}

h2,
h3 {
  font-family: 'CooperMdBt', sans-serif !important;
  font-weight: normal;
}

/* p,
ul {
  margin: 0px !important;
} */

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.pb-5 {
  padding-bottom: 25px;
}

.pt-5 {
  padding-top: 25px;
}

.weight-5 {
  font-weight: 500;
}

.weight-4 {
  font-weight: 400;
}

.weight-6 {
  font-weight: 600;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu::after {
  display: none;
}

.banner-heading h1 {
  font-size: 30px !important;
  font-weight: 700;
}

.theme-btn,
.theme-btn-black,
.theme-md-btn {
  background: var(--btn-primary-grad);
  padding: 8px 30px;
  border: 1px solid var(--primary);
  color: white;
  font-weight: 600;
  border-radius: 6px;
  transition: 0.35s ease-in-out;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  /* margin-top: 25px; */
  display: inline-block;
  min-width: 172px;
  /* line-height: 0; */
}

.theme-md-btn {
  padding: 8px 14px;
  font-size: 12px;
  margin: 0 auto;
}

.theme-btn-black {
  background-color: black;
  border-color: black;
  color: white;
}

.theme-btn:hover {
  background: var(--btn-primary-grad-hover);
  color: #FFFFFF;
}

.theme-btn {
  font-size: clamp(12px, 0.83333vw, 18px);
}

.theme-btn-black:hover {
  background-color: white;
  color: black;
}

.hp-container {
  max-width: 1266px;
  padding-inline: 16px;
  width: 100%;
  margin: 0 auto;
}

.hp-footer-container {
  max-width: 1400px;
  padding-inline: 10px;
  width: 100%;
  margin: 0 auto;
}

.vh-btn {
  background-color: var(--primary);
  border-radius: 30px;
  border: 1px solid var(--primary);
  color: white;
  text-align: center;
  padding: 7px 20px;
  transition: 0.25s ease-in;
}

.vh-btn:hover {
  background-color: var(--theme-white);
  border: 1px solid var(--primary);
  color: var(--primary);
}

.border-bottom {
  border-bottom: 4px solid #4d4b4b;
}

.radius-60-bg-grey {
  border-radius: 60px;
  background-color: #f4f4f4;
}

.radius-30-bg-grey {
  border-radius: 30px;
  background-color: #f4f4f4;
}

.radius-60-bg-theme {
  border-radius: 60px;
  background-color: var(--primary);
}

.radius-30-bg-theme {
  border-radius: 30px;
  background-color: var(--primary);
}

.bg-grey {
  background-color: #F4F4F4 !important;
}

.theme-color {
  color: var(--primary) !important;
}

.theme-bg {
  background-color: var(--primary) !important;
}

.page-title {
  padding-block: 40px;
  text-align: center;
}

.page-title h2 {
  font-size: clamp(24px, 2vw, 28px);
  font-weight: 600;
  margin-bottom: 12px;
}

.page-title p {
  color: #5D534A;
  max-width: 1100px;
  width: 100%;
  padding-inline: 20px;
  margin: 0 auto;
  font-size: 16px;
}

.detail-service-card {
  display: flex;
  justify-content: space-evenly;
  column-gap: 40px;
  align-items: center;
}

.detail-service-card .detailed-text h3 {
  font-size: clamp(26px, 2.25vw, 32px);
  font-weight: 400;
  margin-bottom: 16px;
  line-height: 43px;
}

.detail-service-card .detailed-text p {
  font-size: clamp(16px, 2vw, 20px);
  font-weight: 500;
}

.detail-service-card .detailed-text li {
  /* font-size: 16px; */
  font-size: 14px;
  font-weight: 700;
  color: #7a7a7a;
  line-height: 1.6;
}

.detailed-text {
  width: 100%;
  max-width: max(40%, 600px);
}

@media only screen and (min-width: 992px) {
  .detailed-text {
    flex: 0 0 40%;
  }
}

.detailed-box {
  padding-bottom: 20px;
}

.pricing-card {
  display: inline-block;
  border-top: 10px solid var(--primary);
  padding-top: 20px;
  box-shadow: 0px 7px 23px -12px var(--primary);
  padding-inline: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  text-align: left;
  transition: 0.35s ease-in;
  cursor: pointer;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  background-color: #fff;
}

.pricing-card p span {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  margin-right: 8px;
  color: black !important;
}

.pricing-card p {
  font-size: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}

.pricing-card ul {
  margin: 0;
  padding-left: 12px;
}

.pricing-card li {
  font-size: 16px;
  list-style: none;
  line-height: 1.6;
  font-family: "Poppins", serif;
  padding-left: 8px;
  color: black;
  margin-bottom: 8px;
}

.pricing-card li::marker {
  content: url(../public/assets/images/rounded-check.svg);
  padding: 6px;
}

.pricing-card button.theme-md-btn {
  width: 100%;
  font-size: 16px;
}

.pricing-card-row {
  padding-block: 30px;
}

.pricing-card:hover {
  box-shadow: none;
}

.services-box {
  display: flex;
  gap: 5%;
}

.services-box .img {
  max-width: 50%;
  flex: 0 0 50%;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.services-box .text {
  flex-direction: column;
  display: flex;
  justify-content: center;
  /* gap: 14px; */
  max-width: 420px;
  width: 100%;
  padding: 10px;
}

.services-box .text h3 {
  /* font-weight: 700; */
  font-size: clamp(12px, 2.22222vw, 32px);
}

.renovation-service {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 60px;
}

section.testim.gallerySlider img {
  height: 415px !important;
  object-fit: contain;
  width: 100% !important;
  border-radius: 5px !important;
}

section.testim.gallerySlider {
  border: 0px;
  padding: 0;
  margin: 0;
}

section.testim.gallerySlider ul#testim-dots {
  bottom: -8px;
}

section.testim.gallerySlider .active {
  padding: 0;
}


.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: var(--primary) !important;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: white
}

.services-box li {
  text-align: left;
  margin-bottom: 5px;
}

.services-box li {
  text-align: left;
  margin-bottom: 5px;
}

.main-header-dropdown {
  display: flex;
  justify-content: space-around;
}

.main-header-dropdown a.ant-dropdown-trigger.ant-dropdown-link {
  padding: 10px 16px;
  flex-grow: 1;
  text-align: center;
  color: black;
  position: relative;
}

a.ant-dropdown-trigger.ant-dropdown-link.ant-dropdown-open {
  background: white;
  color: var(--primary) !important;
  font-weight: 600;
}


.main-header-dropdown a.ant-dropdown-trigger.ant-dropdown-link:after,
.main-header-dropdown a.ant-dropdown-trigger.ant-dropdown-link.ant-dropdown-open:after {
  content: '';
  position: absolute;
  height: 8px;
  width: 8px;
  border: 6px solid;
  border-color: black transparent transparent transparent;
  top: 56%;
  transform: translateY(-50%);
  margin-left: 11px;
}

.main-header-dropdown a.ant-dropdown-trigger.ant-dropdown-link.ant-dropdown-open:after {
  border-color: var(--primary) transparent transparent transparent;
}

.terms-and-makePayment {
  display: flex;
  gap: 10px;
}

.ant-steps.ant-steps-horizontal.ant-steps-label-vertical .ant-steps-item-icon {
  background: var(--primary);
  border-color: var(--primary);
}

.ant-steps.ant-steps-horizontal.ant-steps-label-vertical .ant-steps-item-icon path {
  color: white
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: var(--primary);
  height: 3px
}

.steps-container {
  padding-block: 20px;
  position: sticky;
  top: 0;
  z-index: 991;
  background: white;
  box-shadow: 0px 0px 10px #bfbfbf;
}

.ant-steps-item-content {
  margin-top: 0 !important;
}

.selected-style .design-card.selected {
  background: #32ae96c4;
  border-radius: 5px;
}

.see-examplebtn {
  color: navy;
  cursor: pointer;
  font-size: 12px;
}

.see-examplebtn:hover {
  text-decoration: underline;
}

.page_banner {
  background: url(/public/assets/images/banner.png) no-repeat center;
  background-size: cover;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 80px;
}

.page_banner .page-title {
  max-width: 1150px;
  color: white;
  position: relative;
  z-index: 9;
  filter: drop-shadow(2px 4px 6px black);
}

.page_banner .page-title p {
  color: white;
}

.page_banner:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  backdrop-filter: brightness(0.7);
}

.order-form-container img.ant-upload-list-item-image {
  width: 150px !important;
  height: 150px !important;
  object-fit: contain !important;
}

.order-form-container .ant-upload-list-item.ant-upload-list-item-done {
  height: 140px !important;
}

.detailed-text span {
  font-size: 20px;
  margin-block: 10px;
  display: block;
  color: black;

}

.transform-div .transform-div_card {
  position: relative;
  padding-top: 50px;
}

.transform-div .transform-div_card .pointerName {
  position: absolute;
  top: 0;
  left: -30px;
  z-index: -1;
  display: flex;
  width: 80px;
  height: 80px;
  padding-bottom: 25px;
  padding-right: 25px;
  background: #e4a951;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  border-radius: 50%;
  font-weight: 900;
}

.transform-div_card p {
  text-align: justify;
}

.after-line {
  position: relative;
}

/* .after-line::after {
  content: '';
  max-width: 60%;
  width: 100%;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-color: #c3c3c3;
} */

.blog-content p {
  text-align: justify;
}

section.how-it-works.radius-60-bg-grey.mt-5.aos-init.aos-animate {
  width: 100%;
  padding: 12px;
  border-radius: 0;
  padding-block: 70px;
  background: linear-gradient(90deg, rgba(62, 155, 164, 0.1) 0%, rgba(48, 178, 147, 0.1) 100%);
}

.banner-content-links .theme-btn,
.long-btn {
  max-width: 350px !important;
  width: 100%;
  display: inline-block;
}

.detailed-image {
  max-width: 650px;
  width: 100%;
}

.detailed-image img {
  width: 100%;
}

section.what_impact_section {
  background: linear-gradient(57deg, #3e9ba440 10%, #fff 100%);
}

@media (max-width:991px) {
  .footer-section {
    padding-top: 20px;
  }

  .theme-btn {
    display: block;
    margin: 0 auto;
  }

  .detailed-text {
    max-width: 100%;
    width: 100%;
  }

  .detail-service-card {
    flex-direction: column-reverse;
    max-width: 700px;
    margin: 0 auto;
  }

  .detail-service-card .detailed-image img {
    width: 100%;
  }

  .detailed-text {
    max-width: 100%;
    padding-top: 20px;
  }

  .main-header-dropdown.aos-init.aos-animate a.ant-dropdown-trigger.ant-dropdown-link {
    font-size: 14px;
  }

}

@media (max-width:768px) {
  .services-box {
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 20px;
  }

  .single-cta {
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .main-header-dropdown {
    flex-wrap: wrap;
  }

  .main-header-dropdown a.ant-dropdown-trigger.ant-dropdown-link {
    width: 49%;
  }
}

@media (max-width: 480px) {
  .main-header-dropdown a.ant-dropdown-trigger.ant-dropdown-link {
    width: 100%;
  }
}


.flip-card-section .row.no-gutters {
  margin: -5px !important;
}

.flip-card-section .row.no-gutters>* {
  padding: 5px !important;
}

.flip-card-section .flipper {
  padding-bottom: 93%;
  position: relative;
  -webkit-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.col-12.small-flips .flipper {
  padding-bottom: 49%;
}

.col-12.small-flips .review-flip-card {
  display: flex;
  height: 93%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.col-12.small-flips .review-flip-card .reviewImage {
  flex: 0 0 25%;
  max-width: 25%;
}

.review-flip-card .p-2 {
  overflow-y: auto;
  /* scrollbar-width: 2px; */
}

.review-flip-card .p-2::-webkit-scrollbar {
  display: none;
}

.review-flip-card .p-2::-webkit-scrollbar {
  width: 2px;
  margin-left: 5px;
}

.col-12.small-flips img {
  height: 100%;
  object-fit: fill;
}

.flip-card-section .slick-list .flipper {
  padding-bottom: 130%;
}

section.flip-card-section ul.slick-dots.slick-dots-bottom {
  bottom: -30px;
  /* background: red; */
}

section.flip-card-section ul.slick-dots.slick-dots-bottom button {
  background: darkgrey;
}

section.flip-card-section ul.slick-dots.slick-dots-bottom li.slick-active button {
  background: black !important;
}

.reviewImage {
  min-width: 250px;
}

@media (min-width: 576px) {

  .flip-card-section .ratio-not-1-1 {
    padding-bottom: calc(50% - 5px);
  }

}

.flip-card-section .flipper-card {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.flip-card-section .flipper-front,
.flip-card-section .flipper-back {
  position: absolute;
  width: 100%;
  height: 106.5%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
  background: #f5f5f5;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.flip-card-section .flipper-back {
  /* background: #fff; */
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-card-section .flipper-card.flipper-is-flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.clientDetails {
  display: flex;
  gap: 12px;
  margin-bottom: 10px;
}

.clientDetails img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}

.clientDetails span.name {
  display: block;
  font-weight: 700;
  font-size: 24px;
  /* font-size:clamp(21.33333333333338px, 1.6666666666666667vw ,24px); */
  line-height: 36px;
}

.clientDetails span.designation {
  font-size: 18px;
  line-height: 27px;
}

.review-flip-card {
  background: #f5f5f5 !important;
  gap: 20px;
}

.first .review-flip-card div:last-child {
  margin-block: 10px;
}

.review-flip-card .reviewText {
  font-size: 12px;

  text-align: justify;
}

.reviewImage img {
  width: 100%;
}


.flipper.vertical-small-card .review-flip-card {
  display: flex;
  width: 95%;
}

/* services feature section */

.serviceFeatures {
  padding: 40px 20px;
  background: #FCFCEB;
}

.serviceFeatures .features-list ul {
  list-style: none;
  padding: 0;
}

.serviceFeatures .features-list ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #666666;
  line-height: 1.7;
  margin-bottom: 10px;
}

.serviceFeatures .features-list ul li img {
  width: 30px;
  border-radius: 0px !important;
  height: 30px;
}

.compare {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.design-text span {
  font-weight: 600;
}

.design-text {
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}


/* Marqueeeeeee */

.marquee {
  margin: 4em 10px;
}

section h2:has(.underLine) {
  margin-bottom: 28px;
}

.underLine {
  border-bottom: 3px solid #d9d9d9;
  padding-bottom: 8px;
}

.marquee h2 {
  font-size: clamp(30px, 5vw, 44px);
  text-transform: uppercase;
}

.blur-background-box {
  position: relative;
}

.blur-background-box::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url('/public/assets/images/green-blur-svg.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  pointer-events: none;
  z-index: -1;
}

.footer_map-section .map-card .span-number span {
  width: 70px;
}

.footer_map-section .map-card div {
  font-family: 'CooperMdBT';
  font-weight: normal !important;
}

.client-rating-slider ul.slick-dots.slick-dots-bottom {
  bottom: -20px;
}

.client-rating-slider ul.slick-dots.slick-dots-bottom li:not(.slick-active) button {
  background: grey;
  height: 5px;
}

.client-rating-slider ul.slick-dots.slick-dots-bottom li.slick-active button {
  height: 5px !important;
  background: #1e4e58 !important
}

.client-rating-slider span.anticon.anticon-arrow-right.slick-arrow.slick-next svg,
.client-rating-slider span.anticon.anticon-arrow-left.slick-arrow.slick-prev svg {
  width: 20px;
  height: 20px;
  color: #1e4e58;
}

.profile-info-box .profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  border: 4px solid var(--primary);
}

.profile-info-box .profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-details {
  position: relative;
}

.profile-details .ant-form-item {
  margin-bottom: 5px;
}

.profile-details .ant-form-item .ant-col.ant-form-item-label {
  padding: 0px;
  font-weight: 600;
}

.profile-details .ant-form-item input {
  color: black;
}

.design-card-box .design-card {
  padding: 10px;
}

Style the scrollbar track::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: var(--theme-deep-green);
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}


/* RESPONSIVVEE CSSSS */

/* Add these styles to your CSS file */

.services-box .flip-container {
  perspective: 1000px;
  width: 100%;
  max-width: 650px;
}

.services-box .flipper {
  position: relative;
  width: 100%;
  min-height: 420px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.services-box .flip-container:hover .flipper {
  transform: rotateY(180deg);
}

.reviewer-name {
  font-size: clamp(12px, 0.9722222222222222vw, 14px);
}

.services-box .front,
.services-box .back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
}

.services-box .front {
  z-index: 2;
  /* front image shows first */
}

.services-box .back {
  transform: rotateY(180deg);
  /* back image is initially hidden */
}

header.ant-layout-header.main-header.static-header {
  background: white !important;
  position: sticky !important;
  transition-duration:0.2s;
  top: 0;
}
/* adding this for the cart section header disappearing problem  */

header.ant-layout-header.main-header.static-header span.ant-menu-title-content,
header.ant-layout-header.main-header.static-header span.ant-menu-title-content a,
header.ant-layout-header.main-header.static-header svg {
  color: black;
}

header.ant-layout-header.main-header.static-header img.cart-logo {
  filter: brightness(0);
}

header.ant-layout-header.main-header.static-header .logo {
  filter: invert(1);
}

.serviceCardImage {
  object-fit: contain;
  height: 100%;
  aspect-ratio: 3 / 2;
  border-radius: 14px;
  background-color: transparent;
}

/* Impact Section */
.impactCard .cardimages {
  position: relative;
}

.impactCard .cardimages .imageimg {
  margin: 0;
  background-color: #e1e1e1;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.impactCard .cardimages .frontImage.imageimg {
  position: relative;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.04s linear;
}

.impactCard .cardimages .backImage.imageimg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.impactCard:hover .cardimages .frontImage.imageimg {
  opacity: 0;
}

.main-header-tabs .ant-tabs-tab.ant-tabs-tab-active a {
  color: white;
}

.main-header-tabs .ant-tabs-tab:not(.ant-tabs-tab-active) a {
  color: var(--theme-deep-green);
}

.services-box .text div:empty {
  display: none;
}

.main-header-tabs ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  margin: 0 auto !important;
}

.verify-otp-inputs {
  padding-top: 20px;
}

.verify-otp-inputs .ant-otp {
  width: 100%;
  justify-content: space-evenly;
}

.verify-otp-inputs .ant-otp input {
  width: 50px;
  height: 50px;
}

.verify-otp-btn i.ant-spin-dot-item {
  background-color: white !important;
}

.review-card {
  max-width: 412px;
  width: 100%;
  border: 1px solid var(--theme-deep-green);
  border-radius: 6px;
  box-shadow: 1px 2px 3px;
}

section.review-section .rfm-marquee-container {
  height: 420px;
}

.review-card .reviewText {
  font-size: 16px;
  text-align: justify;
}

.main-header-tabs .ant-menu-item.ant-menu-item-selected a {
  color: #ffffc2;
}

.banner-content-links a:not(.theme-btn) {
  text-decoration: underline !important;
  color: #ffffc2;
}

*:not(.clientDetails)>img {
  border-radius: 12px !important;
}

.anticon-arrow-right {
  z-index: 10;
  transform: translate(-200%);
}

.anticon-arrow-left {
  z-index: 10;
  transform: translate(200%);
}

.transform .slick-next {
  z-index: 10;
  transform: translateX(-200%) scale(150%);
}

.transform .slick-prev {
  z-index: 10;
  transform: translatex(200%) scale(150%);
}

.SWPVR_LIST {
  font-weight: 500;
  /* font-size: 20px; */
  font-size: clamp(12px, 1.3888888vw, 25px);
  color: #666666;
}

.floorPlan .services-box .text {
  max-width: 480px;
}

.floorPlan .services-box .text p {
  font-size: 16px;
}

.floorPlan .services-box .text ul li {
  color: #7a7a7a;
  font-size: 14px;
}

.slick-slider .slick-next {
  z-index: 9;
  right:-80px;
  transform: translatex(-200%) scale(150%);
}

.slick-slider .slick-prev {
  z-index: 10;
  left:-80px;
  transform: translatex(200%) scale(150%);
}

.virtual-renovation .detailed-text span {
  font-size: 14px;
}

.virtual-renovation .SWPVR_LIST {
  color: #7a7a7a;
}

.virtual-renovation .how-it-works .theme-btn {
  font-size: clamp(12px, 1.11111vw, 18px);
}

.review .review-text {
  text-transform: uppercase !important;
}

.floor-plan .page-title p {
  max-width: 1300px;
  padding-inline: 10px;
  font-size: clamp(12px, 1.25vw, 20px);
}

.how-it-works .theme-btn {
  font-size: clamp(12px, 1.11111vw, 18px);
}

.first-time .theme-btn {
  font-size: clamp(12px, 1.11111vw, 18px);
}

.impactCard .icon-card img {
  border-radius: 0px !important;
}

.impactCard .icon-card p {
  color: #7a7a7a;
}

.impactCard .icon-card p b {
  color: #292929;
  font-family: 'CooperMdBt';
  font-size: clamp(12px, 1.52vw, 20px);
  text-transform: capitalize;
}

.how-it-works {
  /* margin-bottom:80px; */
  margin-bottom: clamp(20px, 5.5555vw, 80px);
}

.what_impact_section .theme-btn {
  font-size: clamp(12px, 1.11111vw, 18px);

}

.subscribe-form .theme-btn {
  /* font-size:14px; */
  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-main .SWPVR_LIST {
  font-size: clamp(12px, 1.111vw, 20px);
}

.services-box ul li {
  color: #7a7a7a;
}

.commercial-main .services-box ul li {
  /* font-size:14px; */
  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-virtual-staging .detailed-text span {
  font-size: clamp(12px, 1.111vw, 20px);
}

.commercial-virtual-staging .underLine {
  display: inline-block;
  min-width: 255px;
}

.commercial-CR .detailed-text .price-per-photo span {
  margin-block: unset;
  /* font-size:14px; */
  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-CR .SWPVR_LIST {
  /* font-size:16px; */
  font-size: clamp(12px, 1.111vw, 20px);

}

.commercial-DTD .detailed-text .price-per-photo span {
  margin-block: unset;
  text-transform: lowercase;
  /* font-size:14px; */
  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-OR .detailed-text .price-per-photo span {
  margin-block: unset;
  text-transform: lowercase;
  /* font-size:14px; */

  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-OR .detailed-text .price-per-photo h3 {
  margin-bottom: 8px;
}

.commercial-OR .page-title b {
  color: #292929;
}

.commercial-IE .page-title b {
  color: #292929;
}

.commercial-IE .detailed-text .price-per-photo span {
  text-transform: lowercase;
  margin-block: unset;
  /* font-size:14px; */
  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-IE .detailed-text .price-per-photo h3 {
  margin-bottom: 8px;
}

.commercial-IE .page-title p {

  /* font-size:16px; */
  font-size: clamp(12px, 1.111vw, 20px);

}

.commercial-OTV .detailed-text .price-per-photo span {
  text-transform: lowercase;
  margin-block: unset;
  font-size: clamp(12px, 0.97vw, 18px);
}

.commercial-OTV .detailed-text .price-per-photo h3 {
  margin-bottom: 8px;
}

.services-box .SWPVR_LIST {
  /* font-size:16px; */
  font-size: clamp(12px, 1.111vw, 17px);
}

.detailed-text span {
  /* font-size:16px; */
  font-size: clamp(12px, 1.111vw, 20px);
  color: #7a7a7a;
}

.graphics-design-CD .detailed-text span {
  color: #666666;
  font-weight: 500;
}

.graphics-design-CD .detailed-text .price-per-photo span {
  color: black;
  margin-block: unset;
}

.graphics-design-CD .detail-service-card .detailed-text li {
  font-weight: 400;
}

.graphics-design-PD .detailed-text .price-per-photo span {
  margin-block: unset;
  color: black;
}

.graphics-design-PD .detailed-text li {
  font-weight: 400;
  color: #7a7a7a;
}

.graphics-design-PD .detailed-text span {
  font-weight: 500;
  color: #666666;
}

.graphics-design-vector-design .detailed-text span {
  font-weight: 500;
  color: #666666;
}

.graphics-design-vector-design .detailed-text li {
  font-weight: 400;
  color: #7a7a7a;
}

.graphics-design-vector-design .detailed-text .price-per-photo span {
  color: black;
  margin-block: unset;
}

.graphics-design-DDD .detailed-text span {
  font-weight: 500;
  color: #666666;
}

.graphics-design-DDD .detailed-text li {
  font-weight: 400;
  color: #7a7a7a;
}

.graphics-design-DDD .detailed-text .price-per-photo span {
  color: black;
  margin-block: unset;
}

.page-title h1 {
  font-size: clamp(29px, 2.222222vw, 32px);
}

.photo-editing-main .rm-padtop {
  padding-top: 10px;
}

.photo-editing-main .services-box li {
  margin-bottom: unset;
  /* font-size:14px; */
  font-size: clamp(12px, 0.97vw, 18px);
}

.photo-editing-main .SWPVR_LIST {
  /* font-size: 20px; */
  font-size: clamp(12px, 1.3888888vw, 20px);
  font-weight: 500;
}

.photo-editing-main .industry-section {
  padding-block: 40px;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-carousel .slick-dots {
  z-index: 2;
}

.header_scrolled img.cart-logo {
  filter: invert(0)
}

header.ant-layout-header.main-header.static-header .logo {
  filter: invert(0)
}

.flip-card-container {
  max-height: 600px;
  position: relative;
  border-radius: 12px;
  /* box-shadow:inset 0px 6px 6px rgba(0,0,0,0.2), inset 0px -6px 6px rgba(0,0,0,0.2); */
  overflow: hidden;
}

/* .flip-card-container::before{
  position:absolute;
  width:100%;
  height:10%;
  content:'';
  background-color: rgba(0,0,0,0.2);
  z-index:10;
  filter:blur(20px);
  top:calc(0px - 25px);
  left:0;
}
.flip-card-container::after{
  position:absolute;
  width:100%;
  height:10%;
  content:'';
  bottom:calc(0px - 25px);
  left:0;
  background-color: rgba(0,0,0,0.2);
  z-index:10;
  filter:blur(20px);
  
} */


.flip-card-container .trey:first-child {
  animation-name: trey;
  animation-duration: 20s;
  transform: translateY(0%);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes trey {
  100% {
    transform: translateY(-50%);
  }
}

.flip-card-container .trey:last-child {
  animation-name: treylast;
  animation-duration: 20s;
  transform: translateY(-50%);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes treylast {
  100% {
    transform: translateY(0%);
  }
}

/* .flip-card-container:hover>.trey{
  animation-play-state:paused;
} */
.flip-card-container .trey:hover {
  animation-play-state: paused;
}

.small-flips {
  margin-top: 10px !important;
}

.ant-menu-submenu-selected .ant-menu-title-content {
  color: #3ba0a0 !important;
}

.header_scrolled .cart-logo {
  filter: invert(1) !important;
}

.ant-menu-item.ant-menu-item-selected span.ant-menu-title-content a {
  /* color: #fcfceb !important; */
  color: #3ba0a0 !important;
}

.header_scrolled .logoTransparent {
  display: none;
}

.logoWhite {
  display: none;
}

.header_scrolled .logoWhite {
  display: unset;
}

.static-header .logoTransparent {
  display: none;
}

.static-header .logoWhite {
  display: unset;
}

.bottom-menu-sub-menu .ant-menu-item.ant-menu-item-selected span.ant-menu-title-content a {
  color: #ffffc2 !important;
}

.aboutus .utility+* {
  display: none !important;
}

.bulkOrder form textarea,
.bulkOrder form input,
bulkOrder form .ant-select.ant-select-outlined {

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}

.bulkOrder .ant-select-selector,
.bulkOrder .ant-upload-list {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

}

.bulkOrder .ant-upload {
  width: 100% !important;
}

#root>div>div.hp-container.bulkOrder>form>div.ant-row.form-row.css-dev-only-do-not-override-1kuana8>div:nth-child(6)>div>div>div.ant-col.ant-form-item-control.css-dev-only-do-not-override-1kuana8>div>div>span>div {
  border-radius: 12px;
}

.paypal-card img.logo {
  width: 150px;
}

header.main-header.static-header:not(.header_scrolled):has(+.banner-slider-home) {
  background: transparent !important;
  position: fixed !important;
}

header.main-header.static-header:not(.header_scrolled):has(+.banner-slider-home) ul li:not(.ant-menu-item-selected) * {
  color: white !important;
}

header.main-header.static-header:not(.header_scrolled):has(+.banner-slider-home) a img.cart-logo {
  filter: invert(0) !important;
}

header.main-header.static-header:not(.header_scrolled):has(+.banner-slider-home) div.logo .logoWhite {
  display: None !important;
}

header.main-header.static-header:not(.header_scrolled):has(+.banner-slider-home) div.logo .logoTransparent {
  display: inline-block !important;
}

header.ant-layout-header.main-header.static-header:has(+.banner-slider-home) {
  position: fixed !important;
}

.steps-row img {
  border-radius: unset !important;
}

.ForgetModal .ant-steps-item-icon {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ForgetModal .ant-steps-item-icon svg {
  fill: white;
}

.profile-info-box .profile-img .ant-image {
  height: 100%;
}

section.services-section .ant-tabs-tab-btn:focus {
  color: var(--theme-deep-green) !important;
}

.design-image img:hover {
  box-shadow: 0 0 20px 10px #1e4e5980;
}

.design-image img {
  transition: 0.5s ease;
}

span.anticon.anticon-arrow-right.right_icon {
  transform: none;
}

.additional_checkboxes_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.additional_checkboxes_box label.ant-checkbox-wrapper {
  width: 100%;
}

.ant-checkbox-checked span.ant-checkbox-inner,
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--theme-deep-green) !important;
}

a.home-button:hover,
button.home-button:hover {
  box-shadow: 4px 4px 10px #1e4e59;
}

a.home-button,
button.home-button {
  transition: 0.5s ease;
  box-shadow: inset 0px 0px 18px #1e4e59, 0px 0px 6px #1e4e59;
}

.banner-slider-home ul.slick-dots li button:before {
  display: none;
}

.ant-form-item-explain-error{
  margin-block:8px;
}
.ant-form-item-explain-error{
  text-transform:capitalize;
}
span.ant-select-selection-search{
  left:0 !important;
  right:0 !important;
  border-radius: 8px !important;
}
span.ant-select-selection-search input[type="search"]{
  padding:0 11px !important;
}

.likein_checkbox label {
  width: 33%;
  margin-block: 2px;
}

.dusk_checkbox label {
  width: 50%;
  margin-block: 2px;
  align-items: center;
}

.dusk_checkbox {
  display: flex;
  flex-wrap: wrap;
}

.likein_checkbox {
  display: flex;
  flex-wrap: wrap;
}

.ant-input-number-handler-wrap {
  display: none !important;
}