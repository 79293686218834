
.circle {
    display: none;
  }
  @media (min-width: 768px) {
    .circle {
      height: 1600px;
      width: 1100px;
      background-color: #228af5;
      position: absolute;
      top: -400px;
      right: -300px;
      z-index: -1;
      border-radius: 50%;
      display: block;
    }
  }
  
  .paypal-box .paypal-card {
    display: flex;
    /* flex-wrap: wrap; */
    margin: 100px auto;
    width: 830px;
    box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
    vertical-align: top;
  }
  
  .card-breakdown {
    background-color: #1E4E58;
    color: #fff;
    width: 220px;
    display: inline-block;
    position: relative;
    /* float: left; */
    padding: 40px 30px;
    border-radius: 5px 0 0 5px;
  }
  .card-breakdown .card-breakdown--list {
    text-indent: 0;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .card-breakdown .card-breakdown--list:before {
    display: none;
    content: "";
  }
  .card-breakdown .card-breakdown--list li {
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .card-breakdown .card-breakdown--list li:last-of-type {
    border-bottom: none;
  }
  .card-breakdown .card-breakdown--list .fa {
    display: inline-block;
    width: 15%;
    float: left;
  }
  .card-breakdown .card-breakdown--list .list-content {
    /* width: 75%; */
    display: inline-block;
  }
  .card-breakdown .card-breakdown--list p {
    font-weight: 300;
    font-size: 13px;
    margin: 0;
  }
  .card-breakdown .card-breakdown--list p .list-bold {
    font-weight: 600;
    display: block;
    font-size: 15px;
    padding-top: 5px;
  }
  
  .card-breakdown--header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding: 10px 0;
  }
  .card-breakdown--header h2 {
    margin: 0;
    font-size: 22px;
    padding-bottom: 15px;
  }
  .card-breakdown--header p {
    padding: 0 0 7px 0;
    margin: 0;
  }
  
  .card-overview {
    /* width: 490px; */
    display: inline-block;
    border-radius: 0 5px 5px 0;
    padding: 0 30px;
    background-color: #fff;
    flex-grow: 1;
  }
  .card-overview hr {
    border-top: 1px solid #c0c5c9;
    box-shadow: none;
  }
  .card-overview .user-info {
    padding-top: 15px;
  }
  
  .overview-header {
    padding: 38px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .paypal-box .logo {
    display: inline-block;
    padding-bottom: 15px;
    width: 100px;
  }
  
  .paypal-box .timestamp {
    display: inline-block;
    float: right;
    padding-top: 5px;
  }
  .timestamp span {
    color: #b2b8be;
    font-size: 15px;
    font-family: inherit;
    font-weight: 600;
  }
  .timestamp span:first-of-type {
    padding: 15px;
  }
  
  .descriptor {
    /* width: 60%; */
    padding-top: 20px;
  }
  .descriptor p {
    font-size: 13px;
    color: #6e7882;
    line-height: 1.5;
  }
  
  .overview-body {
    color: #6e7882;
  }
  .overview-body span {
    color: #000;
  }
  
  .user-info-name {
    font-weight: 600;
    font-size: 18px;
  }
  
  .user-info-text {
    line-height: 1.5;
    font-weight: 500;
  }
  .user-info-text a {
    color: #228af5;
    text-decoration: none;
  }
  
  .purchase-overview {
    color: #525961;
  }
  
  .overview-footer {
    padding: 12px 0;
    margin-top: 30px;
    border-top: 1px solid #c0c5c9;
    text-align: center
  }
  .overview-footer a {
    font-size: 13px;
    font-weight: 600;
    color: grey   
  }
  .overview-footer .invoice-id {
    float: right;
    font-size: 13px;
    color: #6e7882;
    font-weight: 600;
  }

  .paypal-box {
    /* background: linear-gradient(180deg, #228af5 49%, white 49%); */
    background: url(/public/assets/images/banner.jpeg);
    background-size: cover;
    position: relative;
    top: -100px;
    padding-top: 140px;
    padding-bottom: 50px;
}

.paypal-card {
  display: flex;
}

.payment-modal .ant-modal-content {
  padding: 0px !important;
}
.animated-text{
  position:relative;
  animation:zoom 0.35s 0.5s ease-in-out, fade 0.25s ease-in-out;
}
.animated-text::after{
  position:absolute;
  content:'';
  left:0;
  background-color:#32ae96;
  width: 0%;
  height:100%;
  animation:thank 0.5s ease-in-out;
}
@keyframes fade{
  0%{
    color:white;
  }
  100%{
    color:white;
  }
}
@keyframes thank{
  0%{
    width:0;
  }
  50%{
    width:100%;
  }
  100%{
    width:0;
  }
}
@keyframes zoom{
  0%{
    transform:scale(1);
  }
  50%{
    transform:scale(1.5);
  }
  100%{
    transform:scale(1);
  }
}