@import 'home_media.css' screen and (max-width:1200px);
.main-banner {
    min-height: 710px;
    background: url(../../../../public/assets/images/banner.png) no-repeat center;
    background-size: cover;
    background-position: bottom;
    position: relative;
}

.main-banner:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(3px); */
    z-index: 0;
}

.banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-content h1 {
    color: white;
    font-size: clamp(30px, 5vw, 85px);
    text-align: center;
    font-family: 'CooperLtBT';
    font-weight: normal;
}

header .ant-menu-submenu-title span.ant-menu-title-content,
header .ant-menu-item span.ant-menu-title-content a,
header svg {
    color: white;
}

.banner-slider-home ul.slick-dots.slick-dots-bottom li button,
.banner-slider-home ul.slick-dots.slick-dots-bottom li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.banner-content-links {
    max-width: 550px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    text-align: center;
    padding-block: 18px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.banner-content-links a {
    color: white;
}

.wecando-wrapper {
    background: var(--theme-light-yellow);
    padding-block: 40px;
}

.wecando-wrapper li {
    padding-left: 8px;
    padding-bottom: 8px;
    color:#666666;
}

.wecando-wrapper li::marker {
    content: '\2713';
    color: green;
}

.wecando-wrapper p {
    font-weight: 500;
    font-size:18px;
    color: #73736D;
    padding-block: 10px;
}

.wecando-container {
    text-align: center;
    max-width: 880px;
    margin: 0 auto;
    padding-inline: 10px;
}

.wecando-container ul {
    text-align: left;
    column-count: 2;
    max-width:770px;
    margin-inline:auto;
}

ul.list-with-check-sign {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    line-height: 2.5;
    list-style-position: inside;
}

ul.list-with-check-sign li {
    width: 50%;
    text-align: left;
    position: relative;
}

ul.list-with-check-sign li:before {
    content: '';
    width: 28px;
    height: 28px;
    display: block;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    background: url(/public/assets/images/tick.svg) no-repeat center center;
    filter: brightness(10);
}

.vp-banner {
    /* padding-block: 50px; */
}

.vp-banner h1 {
    font-weight: 600;
}


/* Services */
section.services-section .ant-tabs-tab{
    margin-left:100px !important;
}
section.services-section .ant-tabs-tab:first-child{
    margin-left:0px !important;
}
section.services-section {
    /* background: #F4F4F4; */
    border-radius: 15px;
}

section.services-section .ant-tabs-tab {
    padding: 8px 32px;
    border-radius: 30px;
    transition: 0.35s ease;
    /* font-size: 20px; */
    font-size:18px;
}

section.services-section .ant-tabs-tab:hover {
    color: var(--primary)
}

section.services-section .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--theme-deep-green);
    color: #fff !important;
}

section.services-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    font-size:18px;
}

.service-image {
    width: 55%;
}

section.services-section .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none
}


.service-card .service-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0 20px;
}

.service-card {
    border-radius: 30px;
    border: none;
    overflow: hidden;
    padding: 30px 20px;
    /* background: #fff; */
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.service-card .service-image img {
    border-radius: 10px;
    width: 100%;
}

.service-card .service-text {
    /* max-width: 450px; */
    width: 40%;
}

.service-card .service-text h3 {
    font-weight: 400;
    margin-bottom: 25px;
}

.service-card .service-text p {
    font-size: 16px;
    max-width:400px;
    font-weight: 500;
    color: #666666;
    text-align: left;
}

.service-card .service-text li {
    font-size: 14px;
    color: #666666;
    font-weight:normal;
}

.service-card .service-text li b {
    color: #666666;
    font-weight:700;
}
.service-card .service-text p.tnc{
    margin-top:1rem;
    font-weight:400;    
}

.service-row .service-card:not(:last-child) {
    margin-bottom: 16px;
}

a.free-trial {
    display: block;
    margin-top: 20px;
    text-decoration: underline !important;
    color: var(--theme-white);
    font-weight: 600;
}

.radius-60-bg-grey,
.radius-60-bg-theme {
    border-radius: 20px;
}

section h2 {
    font-weight: normal;
}

.page-title h1 {
    font-weight: normal;
    font-family: 'CooperMdBt', sans-serif;
    margin-bottom: 30px;
}

.page-title b {
    margin-bottom: 20px;
    display: block;
}

section {
    padding-block: 80px;
    margin-block: 20px;
}

section.services-section .ant-tabs-nav-wrap {
    margin-bottom: 30px;
}

/* INdustryyy */
.industry-box img {
    /* max-width: 500px; */
    width: 100%;
    border-radius: 11px;
    margin-bottom: 10px;
}

.industry-box {
    padding: 12px;
    text-align: center;
    display: grid;
    height: 100%;
    align-items: start;
    justify-content: space-between;
    align-content: space-between;
}

.industry-box p {
    min-height: 96px;
}

.industry-box h3 {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Poppins' !important;
}

.compareTable {
    max-width: 1090px;
    padding-inline: 10px;
    width: 100%;
    margin: 0 auto;
}

.compareTable table {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #bfbfbf;
    margin: 0 auto;
}

.compareTable h2 {
    text-align: center;
    margin-bottom: 50px;
}

.compareTable table thead tr {
    background: var(--theme-deep-green);
    color: white;
}

.compareTable table th,
.compareTable table td {
    padding: 12px 10px;
    font-size: 18px;
    font-weight: 600;
}

.compareTable tr td:not(:last-child), .compareTable tr th:not(:last-child) {
    border-right: 2px solid #D9D9D9;
}

.compareTable table th {
    width: 100px
}

.traits-box {
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    text-align: center;
    min-height: 180px;
    background-color: #fff;
}

.traits-box img {
    min-height: 70px;
    /* position: relative;
    top: -36px; */
    background: white;
    padding-inline: 14px;
    margin-top: -40px;
    margin-bottom: 20px;
}

.traits-box .text p {
    margin: 0;
    color:#7a7a7a;
}

.traits-box .text span {
    font-weight: 700;
    font-size:20px;
    display: block;
    margin-bottom: 14px;
}

.traits-box .text {
    padding-inline: 20px;
}

.traits-container {
    margin-top: 100px;
}

/* responsive */

@media (max-width: 991px) {

    .main-banner .banner-text {
        padding: 10px 20px;
        left: 5px;
    }

    .vp-banner .ant-row {
        flex-direction: column-reverse;
    }

    .vp-banner {
        padding-top: 10px;
    }

    .service-card .service-box {
        flex-direction: column;
        display: block;
    }

    .service-card {
        padding: 10px;
        /* width: max-content; */
        border-radius: 10px;
        margin: 0 auto;
    }

    .service-row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .service-card img {
        width: 100%
    }

    .service-card .service-text {
        padding-block: 14px;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    ul.list-with-check-sign li {
        width: 100%;
    }

    section {
        padding-block: 26px;
    }

    .image-detail>div {
        flex-direction: column;
    }

    .steps-container {
        display: none;
    }

    .paymentBox {
        flex-direction: column;
    }
}

@media (max-width: 767px) {

    section.services-section .ant-tabs-nav-list {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 14px;
    }

    .service-image {
        width: 100%;
    }

    .service-card .service-text {
        max-width: 100%;
        width: 100%;
    }

    section.first-time {
        padding: 30px 12px;
    }

    .client-rating-slider span.anticon.anticon-arrow-right.slick-arrow.slick-next svg,
    .client-rating-slider span.anticon.anticon-arrow-left.slick-arrow.slick-prev svg {
        display: none !important;
    }

    section.flip-card-section .row.no-gutters {
        margin: 0px !important;
        display: block !important;
    }

    .traits-box img {
        min-height: 40px;
        height: 40px;
        margin-top: -22px;
    }

    .traits-container .row {
        row-gap: 30px;
    }

    .wecando-container ul {
        column-count: 1;
    }


}

@media (max-width: 576px) {
    .service-card {
        width: auto;
    }

    :where(.css-dev-only-do-not-override-1kuana8).ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='.ant-tabs-nav-wrap-ping']) {
        justify-content: unset;
    }
    .compareTable table th,.compareTable table td {
        white-space: nowrap;
        font-size: 14px;
    }
    
    .compareTable table td img {
        width: 22px;
    }
    .serviceFeatures .features-list ul li {
        font-size: 16px;
    }
    
    .serviceFeatures .row {
        flex-direction: column-reverse;
        row-gap: 20px;
    }
    
    .services-box .text {
        max-width: 95%;
    }
    
    .services-box .img {
        max-width: 95%;
    }
    
}
/* Changes by Prabhanshu */
h2.text-white{
    font-weight:100;
}
.compareTable{
    padding-top:0 !important;
}