@import '../../variables.css';
@import 'header-footer_1200.css' screen and (max-width:1200px);
/* HEADER CSS START */
.main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px !important;
    height: auto;
    line-height: 2;
    gap: 20px;
    position: sticky;
    top: 0;
    z-index: 990;
    background-color: transparent !important;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    transition: 0.5s linear;
}

header.ant-layout-header.main-header.header_scrolled {
    background: white !important;
}

header.ant-layout-header.main-header.header_scrolled span,header.ant-layout-header.main-header.header_scrolled svg {
    color: var(--theme-primary-green);
}

header.ant-layout-header.main-header.header_scrolled{
    box-shadow: 0px 0px 8px #bfbfbf;
}

header.ant-layout-header.main-header.header_scrolled img{
    /* filter: invert() */
}
.main-header-tabs {
    position: sticky;
    top: 0px;
    z-index: 999;
}

.main-header ul {
    background-color: transparent !important;
}
.main-banner .banner-text {
    color: var(--theme-white);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;
    z-index: 99;
    padding: 24px 16px;
    backdrop-filter: brightness(0.2);
    border-radius: 10px;
    max-width: 70%;
}

header.ant-layout-header.main-header.css-dev-only-do-not-override-1kuana8s{
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: 0px 0px 3px #bfbfbf;
}

.logo {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
}

.logo img {
    max-width: 230px;
    width: 100%;
}

span.cart-icon-header {
    /* display: none; */
}

header.header_scrolled .ant-menu-item span.ant-menu-title-content a{
    color: #000000;
}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected,
header .ant-menu-item.ant-menu-item-selected span.ant-menu-title-content a {
    color: var(--primary);
    font-weight: 600;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after {
    display: none;
}

li.ant-menu-overflow-item.ant-menu-item:hover span.ant-menu-title-content {
    color: var(--primary);
}

.main-header-tabs .ant-tabs-nav {
    background-color: #fcfceb;
    margin-bottom: 0px;
}

.main-header-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary) !important;
    font-weight: 600;
}

.main-header-tabs .ant-tabs-tab {
    min-width: 200px;
    justify-content: center;
}

.main-header-tabs 
 .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--theme-deep-green);
    color: white;
}

.main-header-tabs .ant-tabs-tab.ant-tabs-tab-active div.ant-tabs-tab-btn {
    color: white !important;
}

.main-header-tabs .ant-tabs-tab.ant-tabs-tab div.ant-tabs-tab-btn {
    color: var(--theme-deep-green);
    font-weight: bold;
    letter-spacing: 1.2px;
}
/* .main-header-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--theme-white);
} */

.main-header-tabs .ant-tabs-tab:hover {
    color: #fff !important;
}

.main-header-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: white;
    height: 40px;
    position: absolute;
    z-index: -1;
    border-radius: 30px;
    top: 3px;
}

.main-header-tabs .ant-tabs-content-holder * {
    background-color: var(--theme-deep-green) !important;
    color: white;
}

.main-header-tabs .ant-tabs-content.ant-tabs-content-top {
    min-height: 45px;
}

.main-header-tabs li.ant-menu-overflow-item.ant-menu-item:not(.ant-menu-item-selected) {
    color: var(--grey);
}

.main-header-tabs .ant-tabs-tab-btn {
    color: white;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-menu::before,
:where(.css-dev-only-do-not-override-1kuana8).ant-menu-horizontal::after {
    display: none;
}

ul.ant-menu.ant-menu-sub.ant-menu-vertical li.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child {
    background-color: var(--primary) !important;
    color: white !important;
    font-weight: 600;
}


/* HEADER CSS END */

/* FOOTER CSS START */

.footer-section {
    background: var(--theme-deep-green);
    position: relative;
    border-top: 4px solid var(--primary);
}

/* .footer-cta {
    border-bottom: 1px solid #373636;
} */

.single-cta i {
    color: var(--primary);
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: inline-block;
}

.cta-text h4 {
    color: #626262;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text span {
    color: #757575;
    font-size: 15px;
}

.footer-content {
    position: relative;
    z-index: 2;
    max-width: 1264px;
    margin: 0 auto;

}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

/* .footer-logo {
    margin-bottom: 30px;
} */

.footer-logo img {
    width: 100%;
    max-width: 200px;
}

.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #7e7e7e;
    line-height: 28px;
}

.footer-social-icon span {
    color: #626262;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'cooperbi', sans-serif;
    margin-bottom: 20px;
}

.footer-social-icon .footer-social-link{
    display: flex;
}

.footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-social-icon a img{
    aspect-ratio: 1/1;
    width: 20px;
}

.footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
}

.facebook-bg {
    background: #3B5998;
}

.twitter-bg {
    background: #55ACEE;
}

.google-bg {
    background: #DD4B39;
}

.footer-widget-heading h3 {
    color: #292929;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
    position: relative;
    text-align: center;
}

.subscribe-detail .footer-widget-heading h3{
    font-size: 32px;
    font-weight: 400;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -11px;
    height: 3px;
    transform: translateX(-50%);
    width: 50px;
    background: var(--primary);
}

.subscribe-detail .footer-widget-heading h3::before {
    width: 150px;
    background: #d9d9d9;
}

.footer-widget ul li {
    text-align: left;
    margin-bottom: 12px;
    list-style: none;
}

.footer-widget ul li a:hover {
    color: var(--primary);
}

.footer-widget ul li a {
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #fff;
    border: 1px solid #bfbfbf;
    color: #000000;
}

.footer-widget.second a {
    color: #bfbfbf;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: var(--primary);
    padding: 13px 20px;
    border: 1px solid var(--primary);
    top: 0;
    bottom: 0;
    color: #fff;
    height: calc(73px - 21px);
}

.subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
}

.copyright-area {
    background: #202020;
    padding: 25px 0;
}

.copyright-text p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.copyright-text p a {
    color: var(--primary);
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
}

.footer-menu li:hover a {
    color: var(--primary);
}

.footer-menu li a {
    font-size: 14px;
    font-weight: 500;
    color: #878787;
}
footer .footer-cta {
    padding-bottom: 15px;
    /* padding-top: 30px; */
}

.footer-widget.subscribe-detail {
    padding-block: 80px;
    padding-inline: 16px;
    max-width: 768px;
    margin: 0 auto;
    width: 100%;
}
header .ant-menu-title-content:hover{
    color:#3ba0a0 !important;
}
header .ant-menu-title-content:hover span a{
    color:#3ba0a0 !important;
}
header.undefined {
 display:none !important;
}
/* FOOTER CSS END */

/* Media Query */

@media (min-width: 992px) {
    header [aria-label="menu"] {
        display: none;
    }
}

@media (max-width: 991px) {
    .logo {
        max-width: 50%;
    }

    .main-navbar {
        display: none;
    }
}

@media (max-width: 991px) {
    .upper-footer .footer-logo-box {
        width: 60%;
    }
    .upper-footer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .footer-cta .ant-col {
        margin-bottom: 20px;
    }
}

@media (max-width: 960px) {
    span.cart-icon-header {
        display: inline;
    }
    
}

@media (max-width: 768px) {
    .main-header {
        padding: 20px 12px !important;
    }
    footer .footer-cta{
        padding-top: 15px;
    }
    .copyright-area{
        margin-top: 10px;
    }
    .copyright-text p{
        text-align: center;
    }
}

@media (max-width:576px){
    .upper-footer{
        display: block;
    }
    .upper-footer .footer-logo-box {
        max-width: 100%;
        width: 100%;
    }
    .footer-social-icon {
        text-align: center;
        margin-bottom: 16px;
    }
    .footer-widget ul li {
        width: 49%;
        text-align: center;
    }
    
    .footer-widget ul {
        padding: 0;
    }
    .footer-widget {
        padding-block: 15px;
    }
    .footer-logo{
        text-align: center;
    }
}
.main-header ul li.ant-menu-overflow-item.ant-menu-item{
    /* margin-left:60px; */
    margin-left:clamp(10px,4.1666666666667vw, 60px);
    font-size:16px;
}

.main-header ul li{
    /* margin-left:10px; */
    margin-left:clamp(10px,4.1666666666667vw, 60px);
    /* font-size:16px; */
    font-size:16px;
    
}
a:has(.cart-logo){
    margin-left:40px;
}

.footer_map-section {
    background: white;
    padding-inline: 40px;
    padding-block: 206px;
    background-image: linear-gradient(0deg, #3e9ba410 0%, #30b29310 50%, #FFFFFF10 100%), url(/public/assets/images/map.png);
    background-repeat: no-repeat;
    background-position: center;
}

.footer_map-section .map-card {
    text-align: center;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
}

.footer_map-section .map-card .span-number {
    font-size: 56px;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_map-section .map-card span, .footer_map-section .map-card div {
    font-weight: 700;
    display: block;
    font-size: 20px;
    /* margin-top: 10px; */
    
}

.footer_map-section .map-card p {
    margin-top: 9px;
    font-size: 16px;
}

.footer-logo-box a {
    display: block;
    color: white;
    margin-bottom: 12px;
    font-size: 18px;
}

.footer-widget, .footer-widget > div {
    height: 100%;
}

.footer-logo-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer-content .row {
    padding-bottom: 30px;
}

.footer-social-icon {
    padding-block: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid white;

}

.footer-social-icon img {
    width: 22px;
}


.flipper.vertical-small-card .reviewImage {
    width: 40%;
    margin-right: 14px;
}

.flipper.vertical-small-card .review-flip-card .client-text {
    width: 70%;
}


.flipper.vertical-small-card .reviewImage img {
    min-height: 300px;
}

.footer-content .footer-links-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}

.main-header-dropdown .ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected a{
    color: #E0DFAF !important;
}

.footer-menu .footer-menu-heading {
    margin-bottom: 20px;
}

.footer-menu .footer-menu-heading .text {
    font-family: 'Poppins',sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.footer-menu .footer-menu-text p{
    margin-bottom: 0px;
    font-size: 16px;
    color: #FFFFFF;
    white-space: nowrap;
}

.footer-menu .footer-menu-text a{
    font-size: 16px;
    color: #FFFFFF;
}

.footer-menu .footer-menu-menu ul {
    padding: 0px;
}

.footer-menu .footer-menu-menu ul li{
    margin: 4px 0px;
    display: block;
}

.footer-menu .footer-menu-menu ul li .footer-menu-link{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #ffffffba;
    margin: 0px;
}

.footer-menu .footer-menu-menu ul li .footer-menu-link:hover{
    text-decoration: underline !important;
}

.footer-row{
    display: flex;
    margin: 0 -20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-col {
    padding: 0 20px;
}

.footer-inner-section{
    margin-bottom: 30px;
}

.compareTable .table-responsive {
    box-shadow: 0px 0px 10px #bfbfbf;
}
.footer-logo{
    text-align:center;
}
img.only-logo{
    max-width:95px;
    margin-bottom:11px;
}
img.written{
    display:block;
    border-radius:Unset !important;

}
.footer-menu-heading{
    padding-top:10px;
}
.footer-menu>.footer-menu-heading:first-child{
    padding-top:unset;
}
.subscribe-form .ant-form-item {
    margin: 0;
}

.main-header-tabs li.ant-menu-overflow-item.ant-menu-item:hover a{
    color: var(--theme-yellow) !important;

}
body:has(.login-root) .utility.somethingElse+*{
    display:none !important;
}
#root > div > header > ul > li *{
    vertical-align: middle;
}