@media screen and (max-width:1200px){
body>*{
    /* width:100% !important; */
}
.main-header ul.main-navbar li.ant-menu-submenu{
    margin-left:clamp(10px,1.1666666666667vw, 60px);
}   
.main-header ul.main-navbar li.ant-menu-overflow-item.ant-menu-item{
     margin-left:clamp(10px,1.1666666666667vw, 60px);

 }
}
@media screen and (max-width:992px){
    div.logo{
        max-width:unset;
        flex-grow:1;
    }
    div.main-header-dropdown{
        flex-wrap:nowrap;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    div.main-header-dropdown::-webkit-scrollbar{
        display:none;
    }

    a.ant-dropdown-trigger{
        font-size:12px;
    }
    div.main-header-dropdown .ant-dropdown-trigger{
        min-width:40%;
        flex-grow:0;
    }
    section.services-section div.ant-tabs-tab{
        margin-left:0px;
        padding:4px 16px;
        flex-grow:0;
        margin-left:20px !important;
    }
    section.services-section .ant-tabs-tab div.ant-tabs-tab-btn{
        /* font-size:12px; */
        font-size:clamp(14px, 1.8145161290323vw, 18px);
    }
    section.services-section .ant-tabs-tab.ant-tabs-tab-active div.ant-tabs-tab-btn{
        /* font-size:12px; */
        font-size:clamp(14px, 1.8145161290323vw, 18px);
    }
    section.services-section div.ant-tabs-nav-list{
        flex-wrap:nowrap;
        padding-inline:18px;
        /* overflow-x:auto; */
        /* max-width:100%; */
        scrollbar-width:none;
        -ms-overflow-style:none;
    }
    div.ant-tabs-nav{
        overflow-x:auto;
        min-width:100%;
    }
    div.ant-tabs-nav-wrap{
        flex-wrap:nowrap;
        min-width:100%;
        overflow-x:auto;
    }
    section.services-section div.ant-tabs-nav-list::-webkit-scrollbar{
        display:none;
    }
    div.service-card div.service-box{
        display:flex ;
        flex-direction:row ;
    }
    div.service-card div.service-text p{
        /* font-size range from 12-16px */
        font-size:clamp(12px,1.613vw, 16px);
        
    }
    div.service-card div.service-text li{
        font-size:clamp(12px,1.413vw, 14px);

    }
}
@media screen and (max-width:760px){
    div.service-card div.service-box{
        display:flex ;
        flex-direction:column;
    }
    .footer-section *{
        font-size:clamp(12px, 2.105vw, 16px) !important;
    }
   
    div.service-card div.service-text p{
        /* font-size range from 12-16px */
        font-size:16px;
        max-width:unset;
    }
    section.how-it-works h2.border-bottom{
        border-bottom:none !important;
    }
    div.service-card div.service-text p br{
        display:none;
    }
    div.service-card div.service-text li{
        font-size:14px;
    }
    .table-responsive{
        scrollbar-width:none;
        -ms-overflow-style:none;
    }
    .table-responsive::-webkit-scrollbar{
        display:none;
    }
    .footer-row .footer-col:first-child{
        order:1;
        width:100%;
        border-bottom:1px solid #32AE96;
        margin-bottom:30px;
    }
    .footer-row .footer-col:nth-child(2){
        order:2;
        border-bottom:1px solid #32AE96;
        margin-bottom:30px;
    }
    .footer-row .footer-col:nth-child(2) .footer-service-menus .row>*{
        width:33.33% ;
    }
    .footer-row .footer-col:first-child .footer-logo-box{
        flex-direction:row;
    }
    .footer-row .footer-col:first-child .footer-logo-box .footer-logo a{
        width:80%;
    }
    .footer-row .footer-col:first-child .footer-logo-box>*{
        /* min-width:30%; */
        flex-grow:1;
    }
    .footer-row .footer-col:first-child .footer-logo-box .footer-logo .footer-menu{
        width:70%;
    }
    .footer-row .footer-col:first-child .footer-inner-section.footer-menu{
        text-align:right;
    }
    .footer-inner-section.footer-logo{
        display:flex;
        align-items:center;

    }
    .footer-inner-section.footer-logo a{
        margin-bottom:0px;
    }
    .footer-menu .footer-menu-heading h3.text{
        text-wrap:auto ;
    }
    .footer-row .footer-col:nth-child(3){
        order:3;
        width:100%;
    }
    .footer-row .footer-col:nth-child(3) .footer-main-menus.footer-logo-box .footer-inner-section:first-child .footer-menu{
        flex-direction:row;
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        max-width:100%;
    }
    .footer-row .footer-col:nth-child(3) .footer-main-menus.footer-logo-box .footer-inner-section:first-child .footer-menu .footer-menu-heading{
        margin-bottom:10px;
    }
    .footer-row .footer-col:nth-child(3) .footer-main-menus.footer-logo-box .footer-inner-section:nth-child(2) .footer-menu-heading{
        display:none;
    }
    .footer-row .footer-col:nth-child(3) .footer-main-menus.footer-logo-box .footer-inner-section:nth-child(1){
        margin-bottom:10px;
    }
    .footer-row .footer-col:nth-child(3) .footer-main-menus.footer-logo-box .footer-inner-section:nth-child(2) .footer-menu-menu ul{
        display:flex;
        justify-content: space-between;
    }
    .footer-row .footer-col:nth-child(3) .footer-menu .footer-menu-heading{
        padding-top:0px;
        margin-top:0px;

    }
    footer div.subscribe-form input{
        padding:7px 14px;
    }
    footer div.subscribe-form button[type="submit"]{
        padding:7px 14px;
        height:unset;
        min-width:0px;
        border-radius:0px !important;
    }
     div.subscribe-form input{
        padding:7px 14px;
    }
     div.subscribe-form button[type="submit"]{
        padding:7px 14px;
        height:unset;
        min-width:0px;
        border-radius:0px !important;
    }
    
}
@media screen and (max-width:425px){
   
    .footer-row .footer-col:nth-child(3) .footer-main-menus.footer-logo-box .footer-inner-section:first-child .footer-menu{
        justify-content:center;
        column-gap:33px;
    }
}