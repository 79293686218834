:root{
  --primary: #32AE96;
  --secondry: #748D92;
  --grey: #212A31;
  --dim-grey: #2E3944;
  --theme-white: #D3D9D4;
  --theme-deep-green: #1E4E59;
  --theme-yellow : #F5F6D2;
  --theme-light-yellow: #FCFCEB;
  --btn-primary-grad: linear-gradient(90deg, #3e9ba4 0%, #30b293 100%);
  --btn-primary-grad-hover: linear-gradient(90deg, #30b293 0%, #3e9ba4 100%);
}