@media screen and (max-width:425px){
    .service-card .service-image .compare button img{
        /* width:50px; */
        width:clamp(40px, 11.764vw, 50px);
    }
    .Comparison-Slider button img{
        width:clamp(40px, 11.764vw, 50px);
    }
}
@media screen and (max-width:1024px){
    .traits-container .traits-box .text{
        padding-bottom:1.2vmax;
    }
    .traits-container .traits-box{
        min-height:100%;
    }
}
@media screen and (max-width:767px){
    .traits-container div.row{
        row-gap:70px;
    }
}