
/* order-page */
.order-form-container .ant-upload-list-item-container {
    max-width: 220px;
    display: inline-block;
    margin-right: 11px;
  }
  
  .ant-upload-list-item-container .ant-upload-list-item.ant-upload-list-item-undefined {
    flex-direction: column;
    height: auto !important;
    position: relative;
    overflow: hidden;
  }
  
  .ant-upload-list-item-container a.ant-upload-list-item-thumbnail {
    width: 100% !important;
    height: 100% !important;
  }
  
  span.ant-upload-list-item-name {
    display: none;
  }
  
  span.ant-upload-list-item-actions.picture {
    position: absolute;
    top: 0;
    right: 0;
    background: red;
  }
  
  span.ant-upload-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  
  .secondUploadButton .ant-upload-list.ant-upload-list-picture {
    display: none;
}

.coupon-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  max-width: 280px;
  width: 100%;
}

.coupon-label {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}

.coupon-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  /* overflow: hidden; */
}

.coupon-input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 14px;
}

.apply-button {
  background-color: var(--primary);
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.apply-button:hover {
  background-color: #6CC017;
}

.order-summary-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  /* margin: 20px auto; */
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.order-summary h2 {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
}

.order-item,
.order-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.order-total {
  font-weight: bold;
  margin-top: 20px;
}

.payment-secured {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #333;
}

.payment-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.payment-icons img {
  height: 25px;
  margin: 0 5px;
}

.paymentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-steps-item-title {
  font-size: 13px !important;
  color: black !important;
  font-weight: 500;
}




.sky-checkbox-list {
  list-style-type: none;
  padding: 0;
}

.sky-checkbox-list li {
  display: inline-block;
}

.sky-checkbox-list input[type="checkbox"] {
  display: none;
}

.sky-checkbox-list label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.sky-checkbox-list label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.sky-checkbox-list label img {
  height: 100px;
  width: 100%;
  object-fit: contain;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.sky-checkbox-list input[type="checkbox"]:checked + label {
  border-color: #ddd;
}

.sky-checkbox-list input[type="checkbox"]:checked + label:before {
  content: "✓";
  background-color: var(--primary);
  transform: scale(1);
}

.sky-checkbox-list input[type="checkbox"]:checked + label img {
  transform: scale(0.9);
  z-index: -1;
}

ul.sky-checkbox-list label {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  text-align: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

ul.sky-checkbox-list li {
  width: 33.3333%;
  padding: 5px;
}

ul.sky-checkbox-list label span {
  display: block;
  margin-top: 6px;
}
