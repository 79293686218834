@media screen and (max-width:991px){
    div.detail-service-card>div:nth-child(2){
        width:100% !important;
    }
    section.first-time{
        border-radius:20px;
    }
    div.detail-service-card{
        gap:40px;
    }
}
@media screen and (max-width:767px){
    section.how-it-works div.steps-row div.row img{
        width:60px !important;
        height:60px !important;
    }
    .slick-track{
        min-height:unset !important;
        height:max-content !important;
        max-height:unset !important;
    }
    div.compare button img{
        width:48px;
    }
    .underLine{
        border-bottom:0px !important;
        text-decoration:underline;
    }
    div.services-box div.text p.SWPVR_LIST br{
        display:none;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(1){
        order:2;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(2){
        order:1 ;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(3){
        order:3;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(4){
        order:4;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(5){
        order:6;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(6){
        order:5;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(7){
        order:7;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(8){
        order:8;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(9){
        order:10;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(10){
        order:9;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(11){
        order:11;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(12){
        order:12;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(13){
        order:14;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(14){
        order:13;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(15){
        order:15;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(16){
        order:16;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(17){
        order:18;
    }
    div.about_us_wrapper section.truly-apart div.row>div:nth-child(18){
        order:17;
    }
  
}
@media screen and (max-width:567px){
    div.review-flip-card{
        display:flex;
        flex-direction:column;
        border-radius:10px;
        padding:1.2vw 1vw;
    }
    
    .flip-card-section .slick-list div.flipper{
        /* padding-bottom:120%; */
        padding-bottom:clamp(500px, 140%, 793px);
    }
     div.flipper-front, div.flipper-back{
        /* min-height:445px !important; */
        padding:1vw 1vw;
        height:100% !important;
    }
    div.reviewText{
        height:50%;
        overflow:hidden;
        width:100%;
        text-overflow: ellipsis;
        white-space:pre-wrap;
        
    }
    div.review-flip-card div.p-2{
        overflow:hidden;
    }
    
    *::-webkit-scrollbar{
        width:0px !important;
        display:none;
    }
}